<div class="container bg-white mt-2 item-edit" *ngIf="typeObj">
    <div>
        <h2 class="news__title">Antwoorden op vragenlijst</h2>
        <h4 class="text-capitalize">{{typeName}}: {{typeObj?.title}}</h4>
    </div>

    <p *ngIf="answers?.length === 0">Deze vragenlijst is nog door niemand beantwoord.</p>

    <div *ngIf="typeObj.survey">
        <div *ngFor="let question of typeObj.survey.questions">
            <h3 class="mt-3">
                {{question.question}}
                <button *ngIf="question.answers && (question.answers['Ja'] || question.answers['Nee'])" mat-icon-button color="primary" (click)="startChat(question, '')"
                        matTooltip="Start een chat">
                    <svg-icon src="assets/svg/message.svg"></svg-icon>
                </button>
            </h3>
            <ng-container *ngIf="question.type === 'open' && typeObj.schools?.length > 1">
                <div *ngFor="let school of typeObj.schools" class="mb-3 pl-2">
                    <h4>{{school.name}}</h4>
                    <ng-container *ngFor="let answer of answers">
                        <div *ngIf="(answer.user.anySchoolIds | filter : school.id).length > 0">
                            <b>{{answer.user.name}}:</b> {{answer.answers[question.hash]}}
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="question.type === 'open' && !(typeObj.schools?.length > 1)">
                <div *ngFor="let answer of answers">
                    <b>{{answer.user.name}}:</b> {{answer.answers[question.hash]}}
                </div>
            </ng-container>
            <ng-container *ngIf="question.type === 'options'">
                <div *ngFor="let option of question.options" class="answer">
                    <b>{{option}} ({{question.answers ? question.answers[option]?.length ?? 0 : 0}}):</b>
                    <ng-container *ngIf="typeObj.schools?.length > 1 && question.answersSchools && question.answersSchools[option]">
                        <div *ngFor="let school of typeObj.schools">
                            {{school.name}}: {{question.answersSchools[option][school.id]?.length ?? 0}}
                        </div>
                    </ng-container>
                    {{question.answers ? question.answers[option]?.join(', ') : ''}}
                    <button *ngIf="question.answers && question.answers[option]" mat-icon-button color="primary" (click)="startChat(question, option)" matTooltip="Start een chat">
                        <svg-icon src="assets/svg/message.svg"></svg-icon>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="question.type === 'boolean'">
                <div class="answer"><b>Ja
                    <ng-container *ngIf="question.limitCount">({{question.totalCount}} / {{question.limitCount}})</ng-container>
                    <ng-container *ngIf="!question.limitCount">({{question.answers ? question.answers['Ja']?.length ?? 0 : 0}})</ng-container>
                    :</b>
                    @if (typeObj.schools?.length > 1 && question.answersSchools && question.answersSchools['Ja']) {
                        <div *ngFor="let school of typeObj.schools">
                            <b>{{school.name}}: {{question.answersSchools['Ja'][school.id]?.length ?? 0}}</b>
                            <div class="d-flex flex-wrap">
                                @for (usr of question.answersSchools['Ja'][school.id]; track $index) {
                                    <div [innerHTML]="usr"></div>,&nbsp;
                                }
                            </div>
                        </div>
                    } @else {
                        {{question.answers ? question.answers['Ja']?.join(', ') : ''}}
                    }
                    <button *ngIf="question.answers && question.answers['Ja']" mat-icon-button color="primary" (click)="startChat(question, 'Ja')" matTooltip="Start een chat">
                        <svg-icon src="assets/svg/message.svg"></svg-icon>
                    </button>


                </div>
                <div class="answer">
                    <b>Nee
                        <ng-container>({{question.answers ? question.answers['Nee']?.length ?? 0 : 0}})</ng-container>
                        :</b>
                    @if (typeObj.schools?.length > 1 && question.answersSchools && question.answersSchools['Nee']) {
                        <div *ngFor="let school of typeObj.schools">
                            <b>{{school.name}}: {{question.answersSchools['Nee'][school.id]?.length ?? 0}}</b>
                            <div class="d-flex flex-wrap">
                                @for (usr of question.answersSchools['Nee'][school.id]; track $index) {
                                    <div [innerHTML]="usr"></div>,&nbsp;
                                }
                            </div>
                        </div>
                    } @else {
                        {{question.answers ? question.answers['Nee']?.join(', ') : ''}}
                    }
                    <button *ngIf="question.answers && question.answers['Nee']" mat-icon-button color="primary" (click)="startChat(question, 'Nee')" matTooltip="Start een chat">
                        <svg-icon src="assets/svg/message.svg"></svg-icon>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="question.type === 'boolean-quantity'">
                <div class="mb-2 answer"><b>Ja ({{question.totalCount}} / {{question.limitCount}})</b>
                    <button mat-icon-button color="primary" (click)="startChat(question, 'Ja')" matTooltip="Start een chat">
                        <svg-icon src="assets/svg/message.svg"></svg-icon>
                    </button>
                </div>
                <div>
                    <table *ngIf="question.answers" class="table user-count">
                        <tr>
                            <th>
                                Naam
                            </th>
                            <th>
                                Aantal
                            </th>
                        </tr>
                        <tr *ngFor="let answer of question.answers['Ja']">
                            <td>
                                {{answer?.name}}
                            </td>
                            <td>
                                {{answer?.quantity}}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="p-1 mt-5 answer"><b>Nee:</b>
                    {{question.answers ? question.answers['Nee']?.join(', ') : ''}}
                    <button *ngIf="question.answers && question.answers['Nee']" mat-icon-button color="primary" (click)="startChat(question, 'Nee')" matTooltip="Start een chat">
                        <svg-icon src="assets/svg/message.svg"></svg-icon>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

    <button mat-flat-button color="primary" class="mt-5" routerLink="/{{typeName}}/{{typeObj.slug}}">
        Terug naar {{typeName}}item
    </button>
</div>
<div *ngIf="typeObj?.editable && LocalStorage.school.id === typeObj?.school_id" [class.hide]="hideEditButtons" class="floating-buttons">
    <button (click)="delete()" color="primary" mat-fab>
        <mat-icon class="fas fa-trash"></mat-icon>
    </button>
    <button color="primary" mat-fab routerLink="/{{typeName}}/{{typeObj.slug}}/vragenlijst-bewerken">
        <mat-icon class="fas fa-pencil-alt"></mat-icon>
    </button>
</div>
