import {Role} from './role.class';
import {School} from './school.class';
import {Student} from './student.class';
import {Classroom} from './classroom.class';

export class User {
    id: number;
    name: string;
    email: string;
    roles: Role[];
    viaparnassys: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: Date;
    schools: School[];
    phone: string;
    phonework: string;
    direct_schools: School[];
    anySchools: School[];
    anySchoolIds?: number[];
    mainSchool: School;
    students: Student[];
    classrooms: Classroom[];
    auth_log: any[];
    show_on_site: boolean;
    function_one: string;
    function_two: string;
    profile_image: string;
    profile_image_thumb: string;
    calendarUrl: string;
    master_id: number;
    schools_count: number;
    transactionAmount: number;
    direct_school_count?: number;

    chat_only_worktimes?: boolean;
    workday_0?: boolean;
    workday_1?: boolean;
    workday_2?: boolean;
    workday_3?: boolean;
    workday_4?: boolean;
    workday_5?: boolean;
    workday_6?: boolean;

    town: string;
    street: string;
    housenumber: string;
    zipcode: string;
}
