import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ChatMessage} from '../../classes/chat-message';
import {ChatMessageService} from '../../services/chat-message.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CodaltComponent} from '../../codalt.component';
import {combineLatest, fromEvent, Subscription} from 'rxjs';
import {ChatService} from '../../services/chat.service';
import {Chat} from '../../classes/chat';
import {Datasource, IDatasource} from 'ngx-ui-scroll';
import {Platform} from '@angular/cdk/platform';
import {Clipboard} from '@angular/cdk/clipboard';
import {formatDate} from '@angular/common';
import {ImageViewerDialogComponent} from '../../image-viewer-dialog/image-viewer-dialog.component';
import {ImageViewerData} from '../../image-viewer-dialog/image-viewer-data';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {ChatSettingsDialogComponent} from '../chat-settings-dialog/chat-settings-dialog.component';
import {IsBase64Pipe} from '../../pipes/is-base64.pipe';
import {LocalStorage} from '../../storage.class';
import {ChatMessageInfoDialogComponent} from '../chat-message-info-dialog/chat-message-info-dialog.component';
import {AuthorisationService} from '../../services/auth/authorisation.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FileManagerComponent} from '../../file-manager/file-manager.component';
import {IsJsonPipe} from '../../pipes/is-json.pipe';
import {CopyToArticleComponent} from './copy-to-article/copy-to-article.component';
import {FileType} from '../../services/file/file.service';

declare var cordova;
declare var window;

@Component({
    selector: 'app-chat-messaging',
    templateUrl: './chat-messaging.component.html',
    styleUrls: ['./chat-messaging.component.scss']
})
export class ChatMessagingComponent extends CodaltComponent implements OnInit, OnDestroy {

    constructor(private chatMessageService: ChatMessageService,
                private clipboard: Clipboard,
                private chatService: ChatService,
                private platform: Platform,
                private matDialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                private router: Router,
                private breakpointObserver: BreakpointObserver,
                private dialog: MatDialog,
                private isBase64Pipe: IsBase64Pipe,
                private cdref: ChangeDetectorRef,
                private route: ActivatedRoute) {
        super();
    }

    noOtherUsers: boolean;
    archive: boolean;
    chatSubscriptions: Subscription;

    form: UntypedFormGroup;
    messages: ChatMessage[] = [];
    chat: Chat;
    usersMap;
    lastReadCurrentUser;
    lastReadAllUser;
    ownUserId;
    enableDelete = false;
    enableCopy = false;
    enableInfo = false;
    enableAddToArticle = false;
    sending = false;
    loaded = false;
    datasource: IDatasource;
    selectedItems = new Map<number, ChatMessage>();
    chatAdmin = false;
    chatId: number;
    inited = false;

    uploadingFiles = new Map<any, boolean>();

    loadChatTimeout;

    openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.form.get('message').value) {
                this.confirmDialog.confirm(
                    'Openstaand bericht',
                    `Wilt het u geschreven bericht wissen?`,
                    'Hier blijven',
                    'Bericht wissen'
                ).then(() => {
                    resolve(false);
                }, () => {
                    this.form.get('message').reset();
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    ngOnInit(): void {
        this.ownUserId = LocalStorage.user?.id;
        this.subscriptions.add(this.route.params.subscribe(params => {
            const routeData = this.route.snapshot.data as { archive: boolean };
            this.archive = routeData?.archive;
            this.chatId = +params['id'];
            this.loadChat();
        }));

        document.addEventListener('pause', this.chatComponentPause);
        document.addEventListener('resume', this.chatComponentResume);

        this.subscriptions.add(FileManagerComponent.loading.subscribe(id => {
            ChatService.doNotDisconnect = true;
            this.uploadingFiles.set(id, true);
            setTimeout(() => {
                document.querySelector('.messages').scrollBy(0, 99999);
            });
        }));
        this.subscriptions.add(FileManagerComponent.callback.subscribe(id => {
            setTimeout(() => {
                this.cdref.detectChanges();
                this.uploadingFiles.delete(id);
                ChatService.doNotDisconnect = false;
            }, 750);
        }));
    }

    private chatComponentPause = () => {
        setTimeout(() => {
            if (!ChatService.doNotDisconnect || !this.chatService.checkIsOpen()) {
                this.datasource = null;
                this.chatSubscriptions?.unsubscribe();
                this.chatSubscriptions = new Subscription();
                this.chatService.disconnect();
            }
        });
    };

    private chatComponentResume = () => {
        this.uploadingFiles.delete('uploadDialog');
        setTimeout(() => {
            if (!ChatService.doNotDisconnect || !this.chatService.checkIsOpen()) {
                this.loadChat();
            }
        }, 750);
    };

    loadChat() {
        this.loadChatTimeout = setTimeout(() => {
            if (!this.datasource) {
                console.log('4s no datasource, request');
                this.loadChat();
            }
        }, 4000);
        this.datasource = null;
        this.inited = false;
        this.chatSubscriptions?.unsubscribe();
        this.chatSubscriptions = new Subscription();
        this.form = new UntypedFormGroup({
            message: new UntypedFormControl(''),
            chat_id: new UntypedFormControl(this.chatId)
        });
        const chat$ = this.chatService.getChat(this.chatId, this.archive).pipe(distinctUntilChanged((n, o) => {
            this.lastReadCurrentUser = this.chat?.chat_users?.find(u => u.user_id === this.ownUserId)?.last_read;
            this.lastReadAllUser = Math.min(...this.chat.chat_users.map(u => u.last_read));
            this.cdref.detectChanges();
            return n === o && n?.chat_users?.length === o?.chat_users?.length;
        }));
        const users$ = this.chatService.getChatUserMap(this.chatId);
        this.chatSubscriptions.add(combineLatest(chat$, users$).subscribe(([chat, users]) => {
            if (!this.inited && !chat && users.size === 0) {
                clearTimeout(this.loadChatTimeout);
                this.confirmDialog.confirm(
                    'Chat niet gevonden',
                    'Mogelijk ben je uit de chat verwijderd of heb je de chat zelf verlaten.',
                    'Terug', null).then(() => {
                    this.router.navigateByUrl(this.Routenames.chat);
                });
            }

            this.usersMap = users;
            if (chat) {
                this.chat = chat;
            }
            if (this.chat) {
                const chatUser = this.chat.chat_users.find(u => u.user_id === this.ownUserId);
                this.chatAdmin = chatUser.admin;
                this.lastReadCurrentUser = this.chat.chat_users.find(u => u.user_id === this.ownUserId)?.last_read;
                this.lastReadAllUser = Math.min(...this.chat.chat_users.map(u => u.last_read));
                this.noOtherUsers = !this.chat.chat_users.filter(u => u.user_id !== this.ownUserId && !u.user.deleted_at)?.length;
                this.cdref.detectChanges();
            }
            if (!this.inited) {
                this.inited = true;
                let loading = true;
                let first = true;
                this.chatSubscriptions.add(this.chatMessageService.getList(this.chatId).subscribe(messages => {
                    if (!this.datasource) {
                        this.messages = [];
                        const totalCount = this.chatMessageService.totalCount;
                        const aboveIndexToFill = (totalCount - messages.length);
                        for (let i = 1; i <= totalCount; i++) {
                            if (i > aboveIndexToFill) {
                                this.messages.push(messages[messages.length - (totalCount - i) - 1]);
                            } else {
                                this.messages.push(new ChatMessage());
                            }
                        }

                        this.datasource = new Datasource({
                            get: (index, count, success) => {
                                if (!loading && this.messages.length > 0 && typeof this.messages[this.messages.length - 1].id !== 'undefined' && this.messages.filter(m => !!m.id).length !== totalCount) {
                                    this.chatMessageService.requestMessages(this.chat.id, this.messages.find(m => !!m.id).id);
                                    loading = true;
                                }
                                const min = 0;
                                const max = this.chatMessageService.totalCount;
                                const data = [];
                                const start = Math.max(min, index + 1);
                                const end = Math.min(index + count, max);
                                if (start <= end) {
                                    for (let i = start; i <= end; i++) {
                                        if (typeof this.messages[i] !== 'undefined') {
                                            data.push(this.messages[i]);
                                        }
                                    }
                                }
                                success(data);
                                if (first) {
                                    first = false;
                                }
                            },
                            settings: {
                                startIndex: totalCount - 2,
                                bufferSize: 24
                            }
                        });
                        loading = false;

                        this.chatSubscriptions.add(this.datasource.adapter.isLoading$.pipe(debounceTime(500)).subscribe((item: any) => {
                            const lastItem = (this.datasource.adapter.lastVisible.data as any);
                            const chatUser = this.chat?.chat_users?.find(u => u.user_id === this.ownUserId);
                            if (chatUser && (!chatUser.last_read || lastItem.id > chatUser.last_read)) {
                                chatUser.last_read = lastItem.id;
                                this.chatService.lastRead((this.datasource.adapter.lastVisible.data as any).id, this.chat.id).subscribe();
                            }
                        }));
                        this.scrollToEnd();
                    } else {
                        const scrolledToEnd = this.datasource.adapter.lastVisible.$index >= (this.chatMessageService.totalCount - 3);
                        let newData = false;
                        if (this.messages.length < this.chatMessageService.totalCount) {
                            newData = true;
                            for (let i = (this.messages.length); i < this.chatMessageService.totalCount; i++) {
                                const m = new ChatMessage();
                                this.messages.push(m);
                                this.datasource.adapter.append(m, true);
                            }
                        }
                        const newLenght = messages.length;
                        const fromIndex = this.messages.length - newLenght;
                        setTimeout(() => {
                            messages.forEach((m, i) => {
                                if (typeof this.messages[fromIndex + i] !== 'undefined') {
                                    Object.assign(this.messages[fromIndex + i], m);
                                }
                            });
                        });
                        loading = false;
                        if (newData && scrolledToEnd) {
                            this.scrollToEnd();
                        }
                    }
                }, error => {
                    this.confirmDialog.confirm(
                        'Probleem met laden van berichten',
                        'Het is niet gelukt om deze chat te laden',
                        'Opnieuw proberen',
                        'Terug'
                    ).then(() => {
                        this.loadChat();
                    }, () => {
                        this.router.navigateByUrl(this.Routenames.chat);
                    });
                }));

                this.chatSubscriptions.add(fromEvent(window, 'resize')
                    .pipe(debounceTime(50))
                    .subscribe(() => {
                        if (this.datasource.adapter.lastVisible.$index >= (this.chatMessageService.totalCount - 3)) {
                            setTimeout(() => {
                                document.querySelector('.messages').scrollBy(0, 99999);
                            });
                        }
                    }));
            }
        }));
    }

    private scrollToEnd() {
        setTimeout(() => {
            document.querySelector('.messages')?.scrollBy(0, 99999);
            setTimeout(() => {
                document.querySelector('.messages')?.scrollBy(0, 99999);
                setTimeout(() => {
                    document.querySelector('.messages')?.scrollBy(0, 99999);
                }, 750);
            }, 150);

        });
    }

    sendMessage(event?: KeyboardEvent) {

        if (!event || (event.key === 'Enter' && event.ctrlKey)) {
            event?.preventDefault();
            if (!this.sending && this.form.value['message']) {
                this.form.value['message'] = this.form.value['message'].trim();
                this.sending = true;

                const send = () => {
                    this.chatMessageService.newMessage(this.form.value).subscribe(() => {
                        this.form.get('message').reset();
                        this.sending = false;
                    }, () => {
                        this.sending = false;
                        this.confirmDialog.confirm(
                            'Probleem met versturen',
                            'Het is niet gelukt dit bericht te versturen',
                            'Opnieuw proberen',
                            'Annuleren'
                        ).then(() => {
                            send();
                        }, () => {

                        });
                    });
                };
                send();
            }
        }
    }

    readInfo(event) {
        event.stopPropagation();
        const isMobile = this.breakpointObserver.isMatched('(max-width: 450px)');
        let dialogRef = this.dialog.open(ChatMessageInfoDialogComponent, {
            maxHeight: '98vh',
            minHeight: isMobile ? '100%' : '500px',
            height: isMobile ? '100%' : '700px',
            minWidth: isMobile ? '100%' : '400px',
            width: isMobile ? '100%' : '400px',
            panelClass: 'chat-dialog',
            data: {
                chat: this.chat,
                message: Array.from(this.selectedItems.values())[0]
            }
        });
    }

    deleteMessages(event) {
        event.stopPropagation();
        this.chatMessageService.deleteMessage(Array.from(this.selectedItems.keys())).subscribe(() => {
            this.selectedItems.clear();
        });
    }

    copyMessage(event) {
        event.stopPropagation();
        let toCopy = '';
        Array.from(this.selectedItems.values()).forEach(message => {
            toCopy += `📋${this.usersMap.get(message.user_id).name} schreef op ${formatDate(message.updated_at, 'd MMM yyyy HH:mm', 'nl')}
    ${message.message}
`;
        });
        this.clipboard.copy(toCopy.replace(/<br\s*[\/]?>/gi, ''));
        this.selectedItems.clear();
    }

    selectStart(message: ChatMessage) {
        if (this.selectedItems.size < 1 && !message.deleted_at) {
            this.selectedItems.set(message.id, message);
        }
        this.checkActions();
    }

    select($event, message: ChatMessage) {
        if (this.selectedItems.size > 0
            && !message.deleted_at
            && (
                ($event instanceof TouchEvent && this.platform.IOS)
                || ($event.sourceCapabilities && $event.sourceCapabilities.firesTouchEvents && $event instanceof TouchEvent)
                || ((!$event.sourceCapabilities || !$event.sourceCapabilities.firesTouchEvents) && $event instanceof MouseEvent)
            )) {
            if (this.selectedItems.has(message.id)) {
                this.selectedItems.delete(message.id);
            } else {
                this.selectedItems.set(message.id, message);
            }
        }
        this.checkActions();
    }

    checkActions() {
        const treshold = new Date();
        treshold.setUTCHours(treshold.getUTCHours() - (AuthorisationService.hasFeature('deleteOldChatMessages') ? 336 : 1));
        this.enableDelete = !Array.from(this.selectedItems.values())
            .find(m => new Date(m.updated_at).getTime() < treshold.getTime() || m.user_id !== this.ownUserId);
        this.enableCopy = !Array.from(this.selectedItems.values())
            .find(m => this.isBase64Pipe.transform(m.message) || (new IsJsonPipe().transform(m.message)));
        this.enableInfo = this.selectedItems.size === 1 && Array.from(this.selectedItems.values())
            .filter(m => m.user_id === this.ownUserId).length === 1;
        this.enableAddToArticle = !!Array.from(this.selectedItems.values())
            .find(m => (new IsJsonPipe().transform(m.message))) && LocalStorage.user?.schools?.length > 0;
    }

    addToArticle(event) {
        event.stopPropagation();
        const isMobile = this.breakpointObserver.isMatched('(max-width: 450px)');
        let dialogRef = this.dialog.open(CopyToArticleComponent, {
            maxHeight: '98vh',
            minHeight: isMobile ? '100%' : '500px',
            height: isMobile ? '100%' : '700px',
            minWidth: isMobile ? '100%' : '400px',
            width: isMobile ? '100%' : '400px',
            panelClass: 'chat-dialog',
            data: {
                chat: this.chat,
                messages: Array.from(this.selectedItems.values())
            }
        });
    }

    openImage(message: ChatMessage) {
        if (this.selectedItems.size < 1) {
            const images = this.messages
                .filter(m => {
                    if (m.message?.substring(0, 1) === '{' && m.message?.substring(m.message.length - 1) === '}') {
                        try {
                            const mm = JSON.parse(m.message);
                            return mm?.type === 'image';
                        } catch (error) {
                            return false;
                        }
                    }
                    return (new IsBase64Pipe().transform(m.message));
                })
                .map(m => {
                    if ((new IsBase64Pipe().transform(m.message))) {
                        return {
                            id: m.id,
                            base64: m.message,
                            name: `${this.usersMap.get(message.user_id)?.name}-${formatDate(message.updated_at, 'd MMM yyyy H:mm', 'nl')}`.replace(/\s/g, '-').replace(/[^0-9a-z\-]/gi, '')
                        };
                    } else {
                        const item = JSON.parse(m.message);
                        return {
                            id: m.id,
                            path: item.path,
                            chat_id: this.chat.id,
                            name: `${this.usersMap.get(message.user_id)?.name}-${formatDate(message.updated_at, 'd MMM yyyy H:mm', 'nl')}`.replace(/\s/g, '-').replace(/[^0-9a-z\-]/gi, '')
                        };
                    }
                });
            const videos = this.messages
                .filter(m => !!m.video)
                .map(m => m.video);
            let viewIndex = images.findIndex(img => img.id === message.id);
            if (message.video) {
                viewIndex = videos.indexOf(message.video) + images.length;
            }

            this.matDialog.open(ImageViewerDialogComponent, {
                panelClass: 'image-viewer',
                width: '100vw',
                height: '100vh',
                maxWidth: '100vw',
                hasBackdrop: true,
                data: {
                    secure: 'chat',
                    images,
                    videos,
                    viewIndex
                } as ImageViewerData
            });
        }
    }

    editChat() {
        if (!this.archive) {
            const isMobile = this.breakpointObserver.isMatched('(max-width: 450px)');
            this.dialog.open(ChatSettingsDialogComponent, {
                maxHeight: '98vh',
                minHeight: isMobile ? '100%' : '500px',
                height: isMobile ? '100%' : '700px',
                minWidth: isMobile ? '100%' : '400px',
                width: isMobile ? '100%' : '400px',
                panelClass: 'chat-dialog',
                data: this.chat
            });
        }
    }

    ngOnDestroy() {
        clearTimeout(this.loadChatTimeout);
        document.removeEventListener('pause', this.chatComponentPause);
        document.removeEventListener('resume', this.chatComponentResume);
        this.chatMessageService.clearData();
        this.chatSubscriptions?.unsubscribe();
        super.ngOnDestroy();
    }

    readonly FileTypes = FileType;
}
