import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Routenames} from '../route-names.enum';
import {UntypedFormArray, UntypedFormGroup, Validators} from '@angular/forms';
import {Job} from '../classes/job.class';
import {Event} from '../classes/event.class';
import {Article} from '../classes/article.class';
import {ArticleService} from '../services/article.service';
import {JobService} from '../services/job.service';
import {EventService} from '../services/event.service';
import {SurveyService} from '../services/survey.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {EventarticlejobService} from '../services/eventarticlejob.service';
import {CodaltComponent} from '../codalt.component';
import {SurveyAnswer} from '../classes/surveyanswer.class';
import {SurveyQuestion} from '../classes/surveyquestion.class';
import {ChatService} from '../services/chat.service';
import {LocalStorage} from '../storage.class';

@Component({
    selector: 'app-survey-answers',
    templateUrl: './survey-answers.component.html',
    styleUrls: ['./survey-answers.component.scss']
})
export class SurveyAnswersComponent extends CodaltComponent implements OnInit {

    typeArticle = false;
    typeEvent = false;
    typeJob = false;
    typeName = '';
    saving = false;
    typeObj: Article | Event | Job;
    typeService: EventarticlejobService;
    faQuestions = new UntypedFormArray([], Validators.required);
    fc = {
        questions: this.faQuestions
    };
    form = new UntypedFormGroup(this.fc);
    answers: SurveyAnswer[];
    answersPerQuestion: Map<string, {}>;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private articleService: ArticleService,
                private jobService: JobService,
                private eventService: EventService,
                private surveyService: SurveyService,
                private chatService: ChatService,
                private confirmDialogService: ConfirmDialogService) {
        super();
    }

    ngOnInit(): void {
        const firstPart = this.route.snapshot.url[0].path;
        this.typeName = firstPart;
        if (firstPart === Routenames.articles) {
            this.typeArticle = true;
            this.typeService = this.articleService;
        } else if (firstPart === Routenames.events) {
            this.typeEvent = true;
            this.typeService = this.eventService;
        } else if (firstPart === Routenames.jobs) {
            this.typeJob = true;
            this.typeService = this.jobService;
        }

        this.subscriptions.add(this.typeService.get(this.route.snapshot.url[1].path).subscribe((typeObj) => {
            this.typeObj = typeObj.data;
            if (!this.typeObj.survey || this.typeObj.survey?.questions?.length === 0) {
                this.router.navigate([this.typeName, this.typeObj.slug, 'vragenlijst-bewerken'], {replaceUrl: true});
            } else {
                this.subscriptions.add(this.surveyService.getanswers(this.typeObj.survey.id).subscribe(answers => {
                    this.answers = answers.data;
                    this.answersPerQuestion = new Map();
                    this.typeObj.survey.questions.forEach(question => {
                        question.answers = {};
                        question.answersSchools = {};
                        if (question.type === 'open') {
                            question.answersUsers = [];
                        } else {
                            question.answersUsers = {};
                        }

                        this.answers.forEach(surveyAnswer => {
                            const givenAnswer = surveyAnswer.answers[question.hash];
                            if (givenAnswer) {
                                if (Array.isArray(question.answersUsers)) {
                                    question.answersUsers.push(surveyAnswer.user?.id);
                                } else if (typeof question.answersUsers === 'object') {
                                    if (!question.answersUsers[givenAnswer]) {
                                        question.answersUsers[givenAnswer] = [];
                                    }
                                    question.answersUsers[givenAnswer].push(surveyAnswer.user?.id);
                                }

                                if (typeof givenAnswer === 'object') {
                                    question.options.forEach(option => {
                                        question.answers[option] = question.answers[option] || [];
                                        if (!!givenAnswer[option]) {
                                            question.answers[option].push(surveyAnswer.user.name);
                                        }
                                    });
                                } else {
                                    if (!question.answers[givenAnswer]) {
                                        question.answers[givenAnswer] = [];
                                    }
                                    if (!question.answersSchools[givenAnswer]) {
                                        question.answersSchools[givenAnswer] = [];
                                    }
                                    if (givenAnswer === 'Ja' && question.type === 'boolean-quantity') {
                                        const quantity = surveyAnswer.answers[`${question.hash}-quantity`];
                                        question.answers[givenAnswer].push({
                                            name: surveyAnswer.user.name,
                                            quantity
                                        });
                                    } else {
                                        question.answers[givenAnswer].push(surveyAnswer.user.name);
                                    }
                                    surveyAnswer.user.anySchoolIds.forEach(schoolId => {
                                        if (!question.answersSchools[givenAnswer][schoolId]) {
                                            question.answersSchools[givenAnswer][schoolId] = [];
                                        }
                                        const name = `${surveyAnswer.user.name} <small>(${surveyAnswer.user.students.map(s => s.name).join(', ')})</small>`;

                                        question.answersSchools[givenAnswer][schoolId].push(name);
                                    });
                                }
                            }
                        });
                    });
                }));
            }
        }, () => {
        }));
    }

    delete() {
        this.confirmDialogService.confirm('Verwijderen',
            `Weet je zeker dat je deze vragenlijst inclusief antwoorden wilt verwijderen?`, 'Verwijderen', 'Behouden').then(() => {
            this.surveyService.delete(this.typeObj.survey.id).subscribe(() => {
                this.router.navigate([this.typeName, this.typeObj.slug]);
            });
        }, () => {
        });
    }

    startChat(question: SurveyQuestion, answer: string) {
        const users = [...Array.isArray(question.answersUsers) ? question.answersUsers : question.answersUsers[answer] as number[]];
        if (users.indexOf(LocalStorage.user.id) === -1) {
            users.push(LocalStorage.user.id);
        }

        const chatData = {
            name: question.question,
            users
        };

        this.chatService.saveChat(chatData).subscribe(chat => {
            this.router.navigateByUrl(`${Routenames.chat}/${chat.data.id}`);
        });
    }
}
